<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      justify="center"
    >
      <v-col
        md="12"
      >
        <material-card
          id="materialCardTeste"
          color="green"
          class="pt-0 pb-0"
        >
          <v-col
            id="materialCardHeader"
            slot="header"
            class="pt-0 pb-0"
          >
            <h4
              class="pt-0 pb-0 font-weight-light mb-0 mt-0"
            >
              Condominio
              <v-btn
                class="v-btn--simple"
                icon
                @click="novo"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </h4>
          </v-col>
          <v-data-table
            :headers="headers"
            :items="items"
            :pagination.sync="pagination"
            :server-items-length="totalRegistros"
            :loading="loading"
          >
            <template slot="no-data">
              <v-btn
                color="primary"
                @click.stop="novo()"
              >
                Novo
              </v-btn>
            </template>
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-success text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>
                {{ item.nomeCondominio }}
                <v-tooltip
                  top
                  content-class="top"
                >
                  <v-btn
                    slot="activator"
                    class="v-btn--simple"
                    color="success"
                    icon
                    @click="editar(item)"
                  >
                    <v-icon color="success">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                  <span>Editar</span>
                </v-tooltip>
                <v-tooltip
                  top
                  content-class="top"
                >
                  <v-btn
                    slot="activator"
                    class="v-btn--simple"
                    color="danger"
                    icon
                    @click="deleteEventHandler(item)"
                  >
                    <v-icon color="error">
                      mdi-close
                    </v-icon>
                  </v-btn>
                  <span>Remover</span>
                </v-tooltip>
              </td>
              <td>{{ item.blocos }}</td>
              <td>{{ item.totalApartamentos }}</td>
              <td>
                <v-tooltip
                  top
                  content-class="top"
                >
                  <v-btn
                    slot="activator"
                    class="v-btn--simple"
                    color="success"
                    icon
                    @click="editar(item)"
                  >
                    <v-icon color="success">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                  <span>Editar</span>
                </v-tooltip>
                <v-tooltip
                  top
                  content-class="top"
                >
                  <v-btn
                    slot="activator"
                    class="v-btn--simple"
                    color="danger"
                    icon
                    @click="deleteEventHandler(item)"
                  >
                    <v-icon color="error">
                      mdi-close
                    </v-icon>
                  </v-btn>
                  <span>Remover</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import CondominioService from '@/services/CondominiosService'

  export default {
    data: () => ({
      totalRegistros: 0,
      pagination: {},
      headers: [
        {
          sortable: false,
          text: 'Nome',
          value: 'nomeCondominio'
        },
        {
          sortable: false,
          text: 'Blocos',
          value: 'blocos'
        },
        {
          sortable: false,
          text: 'Total Apartamentos',
          value: 'totalApartamentos'
        },
        {
          sortable: false,
          text: 'Ações'
        }
      ],
      items: [],
      loading: false,
      itemRemover: {}
    }),
    created () {
      this.buscar()
    },
    methods: {
      deleteEventHandler (item) {
        this.itemRemover = item
        this.$root.$emit('mostra_dialogo_pergunta', {
          dialogoTitulo: 'Atenção',
          dialogoDescricao: 'Tens certeza que deseja remover este condomínio?',
          funcaoRetorno: this.remover
        })
      },
      remover () {
        const condominio = this.itemRemover
        try {
          CondominioService.delete(condominio)
            .then(response => {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: 'Sucesso',
                dialogoDescricao: 'Condomínio removido com sucesso'
              })
              this.buscar()
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Ocorreu um erro ao remover um condomínio:\n ->' + error
          })
        }
      },
      buscar () {
        CondominioService.findAll(this.pagination)
          .then((condominios) => {
            if (condominios && condominios.data && condominios.data.rows) {
              this.items = condominios.data.rows
            }
          })
          .catch(err => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: 'Erro ao buscar os condomínios cadastrados: \n' + err
            })
          }).finally(() => {
            this.loading = false
          })
      },
      novo () {
        this.$router.push({
          name: 'Cadastro de Condomínio',
          params: null
        })
      },
      editar (item) {
        if (item && item.id) {
          this.$router.push({
            name: 'Formulário do Condomínio',
            params: { id: item.id }
          })
        }
      }
    }
  }
</script>
<style>
#materialCardTeste .v-offset, #materialCardTeste .v-offset .v-card--material__header {
  padding: 0px;
}
</style>
